<!-- Created by henian.xu on 2018/7/20. -->

<template>
    <page>
        <!-- 表单区 -->
        <template slot="secondHeader">
            <el-form
                ref="queryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="queryFormModel"
            >
                <el-form-item
                    prop="goodsCatName"
                    label="类目名称"
                >
                    <el-input
                        v-model="queryFormModel.goodsCatName"
                        placeholder="请输入类目名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                >
                    <el-select
                        v-model="queryFormModel.isAvailable"
                        placeholder="请选择"
                    >
                        <el-option
                            key="1"
                            label="是"
                            value="1"
                        />
                        <el-option
                            key="2"
                            label="否"
                            value="0"
                        />
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onReset"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>
        </template>

        <!-- 内容 -->
        <div
            class="body"
            slot="body"
            v-loading="querying"
        >
            <!-- 操作 -->
            <div
                class="ma-b"
            >
                <el-button-group>
                    <el-button
                        type="success"
                        icon="el-icon-plus"
                        size="small"
                        @click="onAdd"
                    >
                        新增
                    </el-button>
                    <el-button
                        type="danger"
                        icon="el-icon-delete"
                        size="small"
                        @click="onDelete"
                        :disabled="!currentTableSelect.length"
                    >
                        删除
                    </el-button>
                </el-button-group>
            </div>

            <!-- 表格 -->
            <el-table
                stripe
                border
                size="small"
                :data="tableData"
                style="width: 100%"
                @selection-change="onTableSelectionChange"
            >
                <el-table-column
                    type="selection"
                    width="36"
                />
                <el-table-column
                    prop="goodsCatName"
                    label="类目名称"
                    min-width="100"
                />
                <el-table-column
                    prop="orderNum"
                    label="排序号"
                    min-width="100"
                />
                <el-table-column
                    label="是否可用"
                    min-width="150"
                >
                    <template slot-scope="scope">
                        {{ scope.row.isAvailable ? '是':'否' }}
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="145"
                >
                    <template slot-scope="scope">
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableEdit(scope.row)"
                        >
                            编辑
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onTableDelete(scope.row.id)"
                        >
                            删除
                        </el-button>
                        <el-button
                            type="text"
                            size="small"
                            @click="onGoodsForbidSell(scope.row)"
                        >
                            商品管理
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="ma-t ta-r">
                <pagination
                    v-model="pagination"
                    @input="onQuery"
                />
            </div>
        </div>

        <!-- 弹框 -->
        <!-- 新增/编辑 -->
        <el-dialog
            :title="addDialog.formModel.id ? '编辑' : '新增'"
            center
            width="600px"
            :visible.sync="addDialog.isVisible"
            @closed="onAddDialogCancel"
        >
            <el-form
                ref="addForm"
                size="small"
                :model="addDialog.formModel"
                :rules="addDialog.formRules"
            >
                <el-form-item
                    prop="goodsCatName"
                    label="类目名称"
                    label-width="6em"
                >
                    <el-input
                        v-model="addDialog.formModel.goodsCatName"
                        auto-complete="off"
                    />
                </el-form-item>
                <el-form-item
                    prop="orderNum"
                    label="排序号"
                    label-width="6em"
                >
                    <el-input-number
                        controls-position="right"
                        :min="1"
                        :max="255"
                        v-model.number="addDialog.formModel.orderNum"
                    />
                </el-form-item>
                <el-form-item
                    prop="isAvailable"
                    label="是否可用"
                    label-width="6em"
                >
                    <el-switch
                        v-model="addDialog.formModel.isAvailable"
                    />
                </el-form-item>
            </el-form>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onAddDialogConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onAddDialogCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <!--商品管理-->
        <el-dialog
            title="商品管理"
            center
            width="1200px"
            :visible.sync="goodsForbidSellDialog.isVisible"
            @closed="onGoodsForbidSellCancel"
        >
            <!-- 内容 -->
            <div class="body">
                <el-form
                    ref="goodsForbidSellDialogQueryForm"
                    class="second-header npa-b"
                    size="small"
                    :inline="true"
                    :model="goodsForbidSellDialog.queryFormModel"
                >
                    <el-form-item
                        prop="goodsName"
                        label="商品名称"
                    >
                        <el-input
                            v-model="goodsForbidSellDialog.queryFormModel.goodsName"
                            placeholder="请输入商品名称"
                        />
                    </el-form-item>
                    <el-form-item
                        prop="goodsSn"
                        label="商品货号"
                    >
                        <el-input
                            v-model="goodsForbidSellDialog.queryFormModel.goodsSn"
                            placeholder="请输入商品货号"
                        />
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            type="primary"
                            @click="onGoodsForbidSellDialogQuery()"
                        >
                            查询
                        </el-button>
                        <el-button
                            type="success"
                            @click="onResetGoodsForbidSellDialog"
                        >
                            重置
                        </el-button>
                    </el-form-item>
                </el-form>

                <div
                    class="ma-b"
                >
                    <el-button-group>
                        <el-button
                            type="success"
                            icon="el-icon-plus"
                            size="small"
                            @click="onAddSelect()"
                        >
                            选择商品
                        </el-button>
                        <el-button
                            type="danger"
                            icon="el-icon-delete"
                            size="small"
                            @click="onDeleteSelectBatch"
                            :disabled="!goodsForbidSellDialog.currentTableSelect.length"
                        >
                            删除
                        </el-button>
                    </el-button-group>
                </div>

                <el-table
                    stripe
                    border
                    size="small"
                    :data="goodsForbidSellDialog.tableData"
                    style="width: 100%"
                    @selection-change="onGoodsForbidSellDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品编号"
                        min-width="100"
                    />
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="45"
                    >
                        <template slot-scope="scope">
                            <el-button
                                type="text"
                                size="small"
                                @click="onDeleteSelect(scope.row.id)"
                            >
                                删除
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="goodsForbidSellDialog.pagination"
                        @input="onGoodsForbidSellDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    size="small"
                    @click="onGoodsForbidSellCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>

        <el-dialog
            title="选择商品"
            center
            width="1200px"
            :visible.sync="selectDialog.isVisible"
            @closed="onSelectCancel"
        >
            <!-- 表单区 -->
            <el-form
                ref="selectDialogQueryForm"
                class="second-header npa-b"
                size="small"
                :inline="true"
                :model="selectDialog.queryFormModel"
            >
                <el-form-item
                    prop="goodsName"
                    label="商品名称"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsName"
                        placeholder="请输入商品名称"
                    />
                </el-form-item>
                <el-form-item
                    prop="goodsSn"
                    label="商品编号"
                >
                    <el-input
                        v-model="selectDialog.queryFormModel.goodsSn"
                        placeholder="请输入商品编号"
                    />
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSelectDialogQuery()"
                    >
                        查询
                    </el-button>
                    <el-button
                        type="success"
                        @click="onResetSelectDialog"
                    >
                        重置
                    </el-button>
                </el-form-item>
            </el-form>

            <!-- 内容 -->
            <div class="body">
                <!-- 表格 -->
                <el-table
                    stripe
                    border
                    size="small"
                    :data="selectDialog.tableData"
                    style="width: 100%"
                    height="400"
                    row-key="goodsId"
                    ref="selectDialogTable"
                    @selection-change="onSelectDialogSelectionChange"
                >
                    <el-table-column
                        type="selection"
                        width="36"
                    />
                    <el-table-column
                        prop="mainImageUrl"
                        label="商品图片"
                        width="135"
                    >
                        <template slot-scope="scope">
                            <image-list :data="scope.row.mainImageUrl" />
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goodsName"
                        label="商品名称"
                        min-width="100"
                    />
                    <el-table-column
                        prop="goodsSn"
                        label="商品编号"
                        min-width="100"
                    />
                </el-table>

                <!-- 分页 -->
                <div class="ma-t ta-r">
                    <pagination
                        v-model="selectDialog.pagination"
                        @input="onSelectDialogQuery"
                    />
                </div>
            </div>
            <div
                slot="footer"
                class="dialog-footer"
            >
                <el-button
                    type="primary"
                    size="small"
                    @click="onSelectConfirm"
                >
                    确 定
                </el-button>
                <el-button
                    size="small"
                    @click="onSelectCancel"
                >
                    取 消
                </el-button>
            </div>
        </el-dialog>
    </page>
</template>

<script>
import pagesMixin from '@/mixin/layout/pageMixin';
import { queryFormList } from 'vmf/src/mixin';

export default {
    name: 'StoreInitGoodsCat',
    mixins: [pagesMixin, queryFormList],
    data() {
        return {
            queryFormModel: {
                goodsCatName: '', // 类目名称
                isAvailable: '',
            },
            // 新增/编辑
            addDialog: {
                // 对话框显示
                isVisible: false,
                // 表单数据
                formModel: {
                    id: '',
                    goodsCatName: '',
                    orderNum: '',
                    isAvailable: true,
                },
                // 表单校验规则
                formRules: {
                    goodsCatName: {
                        required: true,
                        message: '请输入类目名称',
                        trigger: 'blur',
                    },
                    orderNum: {
                        required: true,
                        message: '请输入排序号',
                        trigger: 'blur',
                    },
                    isAvailable: {
                        required: true,
                        message: '请输入是否可用',
                        trigger: 'blur',
                    },
                },
            },
            // 表格数据
            tableData: [],
            // 表格当前选中行
            currentTableSelect: [],
            selectDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    goodsName: '', // 商品名称
                    goodsSn: '', // 商品编号
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                // 当前选择行
                currentTableSelect: [],
            },
            goodsForbidSellDialog: {
                // 对话框显示
                isVisible: false,
                // 查询数据
                queryFormModel: {
                    storeInitGoodsCatId: '',
                    goodsName: '',
                    goodsSn: '',
                },
                // 表单数据
                tableData: [],
                // 分页
                pagination: {},
                currentTableSelect: [],
            },
        };
    },
    methods: {
        /* -- 方法 -- */
        // 请求列表数据
        getListData(queryData) {
            return this.$api.Sp.StoreInitGoodsCat.data(queryData).then(json => {
                const res = json.data;
                this.tableData = res.data;
                this.pagination = res.pagination;
                return Promise.resolve(json);
            });
        },
        /* -- 事件 -- */
        onAdd() {
            this.addDialog.isVisible = true;
            this.$nextTick(() => {
                const { addForm } = this.$refs;
                addForm.resetFields();
                const { formModel } = this.addDialog;
                formModel.id = '';
            });
        },
        onTableSelectionChange(val) {
            this.currentTableSelect = val;
        },
        onTableEdit(row) {
            const { formModel } = this.addDialog;
            this.$api.Sp.StoreInitGoodsCat.getDetail({ id: row.id }).then(json => {
                const res = json.data.data;
                this.$utils.Common.formModelMerge(formModel, res);
            });
            this.addDialog.isVisible = true;
        },
        onTableDelete(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Sp.StoreInitGoodsCat.delete({ ids }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                });
            });
        },
        onDelete() {
            const ids = this.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onTableDelete(ids.join(','));
        },
        onAddDialogConfirm() {
            const { addForm } = this.$refs;
            addForm.validate().then(() => {
                this.$api.Sp.StoreInitGoodsCat.save({
                    ...this.addDialog.formModel,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onQuery(this.pagination);
                    this.addDialog.isVisible = false;
                });
            });
        },
        onAddDialogCancel() {
            const { addForm } = this.$refs;
            addForm.resetFields();
            const { formModel } = this.addDialog;
            formModel.id = '';
            this.addDialog.isVisible = false;
        },
        //商品管理
        onGoodsForbidSell(row) {
            this.goodsForbidSellDialog.queryFormModel.storeInitGoodsCatId = row.id;
            this.$nextTick(() => {
                this.onGoodsForbidSellDialogQuery();
            });
            this.goodsForbidSellDialog.isVisible = true;
        },
        onGoodsForbidSellDialogQuery(pagination) {
            return this.$api.Gd.Goods.data({
                ...this.goodsForbidSellDialog.queryFormModel,
                ...pagination,
            }).then(json => {
                const res = json.data;
                this.goodsForbidSellDialog.tableData = res.data;
                this.goodsForbidSellDialog.pagination = res.pagination;
            });
        },
        onResetGoodsForbidSellDialog() {
            this.$refs.goodsForbidSellDialogQueryForm.resetFields();
        },
        onGoodsForbidSellDialogSelectionChange(val) {
            this.goodsForbidSellDialog.currentTableSelect = val;
        },
        onGoodsForbidSellCancel() {
            this.goodsForbidSellDialog.isVisible = false;
        },
        onSelectDialogSelectionChange(val) {
            this.selectDialog.currentTableSelect = val;
        },
        onAddSelect() {
            this.selectDialog.isVisible = true;
            this.$nextTick(() => {
                this.onSelectDialogQuery();
            });
        },
        onSelectDialogQuery(pagination) {
            return this.$api.Gd.Goods.selectByPage({
                ...this.selectDialog.queryFormModel,
                ...pagination,
                storeInitGoodsCatIdNE: this.goodsForbidSellDialog.queryFormModel.storeInitGoodsCatId,
            }).then(json => {
                const res = json.data;
                this.selectDialog.tableData = res.data;
                this.selectDialog.pagination = res.pagination;
            });
        },
        onResetSelectDialog() {
            this.$refs.selectDialogQueryForm.resetFields();
        },
        onSelectConfirm() {
            const ids = this.selectDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            if (ids.length === 0) {
                this.$message({
                    message: '请选择商品',
                    type: 'error',
                });
                return;
            }
            this.$api.Gd.Goods.saveGoodsStoreInitGoodsCat({
                goodsIds: ids.join(','),
                storeInitGoodsCatId: this.goodsForbidSellDialog.queryFormModel.storeInitGoodsCatId,
            }).then(json => {
                const res = json.data;
                this.$message({
                    message: res.msg,
                    type: 'success',
                });
                this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                this.selectDialog.isVisible = false;
            });
        },
        onSelectCancel() {
            this.selectDialog.isVisible = false;
        },
        onDeleteSelectBatch() {
            const ids = this.goodsForbidSellDialog.currentTableSelect.reduce((prev, next) => {
                prev.push(next.id);
                return prev;
            }, []);
            this.onDeleteSelect(ids.join(','));
        },
        onDeleteSelect(ids) {
            this.$confirm('是否确定要删除！', '温馨提示', {
                type: 'warning',
            }).then(() => {
                this.$api.Gd.Goods.delGoodsStoreInitGoodsCat({
                    goodsIds: ids,
                    storeInitGoodsCatId: this.goodsForbidSellDialog.queryFormModel.storeInitGoodsCatId,
                }).then(json => {
                    const res = json.data;
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.onGoodsForbidSellDialogQuery(this.goodsForbidSellDialog.pagination);
                });
            });
        },
    },
};
</script>

<style lang="scss">
</style>
